import {
  Team,
  PlayerBundle__AccountType,
  AccountId,
  TeamId,
  Team__Account,
  PlayerBundle,
  Player,
  Team__StaffTypes,
  PlayerId
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../helpers";
import { teamExtractPlayerIdsOnSquad } from "../compute/team.compute";
import { fetchPrettyPlayerList } from "./player-utils";
import _ from "lodash";
import jsonStableStringify from "json-stable-stringify";
import { BatchTask } from "@ollie-sports/firebase";
import { ObjectKeys } from "../utils";
import { common__extractTeamIdAndSquadFromTeamIdWithSquad } from "../api/common.api";
import { compute } from "..";
import { getBatchTasksToUpdateDerivedForAccount } from "../compute/account.compute";
import { getBatchTasksToUpdateDerivedForPlayerBundle } from "../compute/playerBundle.compute";

export async function fetchAccountIdsOnSquad(p: {
  team: Team;
  squad: "a" | "b" | "c";
  squadSubset: "all" | "players" | "team" | "guardians";
}): Promise<string[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const staffAccountIds: string[] = [];
  const guardianAccountIds: string[] = [];
  const playerAccountIds: string[] = [];

  // Add anyone that has been given the explicit permission
  for (let i in p.team.accounts) {
    if ((p.team.accounts[i]?.additionalPermissions as any)?.[`squad_${p.squad}_staff`]) {
      staffAccountIds.push(i);
    }
  }

  // Figure all accounts linked by playerBundles
  const playerIdsOnSquad = teamExtractPlayerIdsOnSquad(p.team, p.squad);
  const playersTemp = await h.Player.getDocs(playerIdsOnSquad);
  const players: Player[] = [];
  playersTemp.forEach(pl => {
    if (pl) {
      players.push(pl);
    }
  });
  const prettyPlayers = await fetchPrettyPlayerList(players);
  prettyPlayers.forEach(pp => {
    if (pp.derived.accountInfoSource === "account" || pp.derived.accountInfoSource === "playerBundle") {
      for (let accountId in pp.derived.playerBundle.managingAccounts) {
        // Extract guardians
        if (pp.derived.playerBundle.managingAccounts[accountId]?.type === PlayerBundle__AccountType.guardian) {
          guardianAccountIds.push(accountId);
        }
        // Extract players
        if (pp.derived.playerBundle.managingAccounts[accountId]?.type === PlayerBundle__AccountType.selfAthlete) {
          playerAccountIds.push(accountId);
        }
      }
    }
  });

  if (p.squadSubset === "all") {
    return _.uniq([...playerAccountIds, ...guardianAccountIds, ...staffAccountIds]);
  } else if (p.squadSubset === "players") {
    return _.uniq(playerAccountIds);
  } else if (p.squadSubset === "team") {
    return _.uniq([...playerAccountIds, ...staffAccountIds]);
  } else if (p.squadSubset === "guardians") {
    return _.uniq(guardianAccountIds);
  } else {
    console.error("Should never arrive here. fetchAccountIdsOnSquad");
    return [];
  }
}

export async function deleteTeam(p: { teamId: TeamId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.Team.update({ id: p.teamId, doc: { deletedAtMS: Date.now() } });
}

export async function undeleteTeam(p: { teamId: TeamId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.Team.update({ id: p.teamId, doc: { deletedAtMS: 0 } });
}

export async function updateDerivedForTeam(p: { teamId: TeamId; executeImmediate: boolean }): Promise<BatchTask[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  const tasks: BatchTask[] = [];

  const team = await h.Team.getDoc(p.teamId);
  if (!team) {
    throw new Error("No team found for id");
  }

  // Fetch all active players
  const activePlayers = (await h.Player.query({ where: [{ deletedAtMS: ["==", 0] }, { teamId: ["==", team.id] }], limit: 1000 }))
    .docs;

  //Fetch all active player bundles
  const activePlayerBundles = await h.PlayerBundle.multiQuery({
    queries: activePlayers.map(pl => ({ where: [{ derived: { linkedPlayers: { [pl.id]: { status: ["==", "active"] } } } }] }))
  }).then(a => a.docs);

  let pendingDerivedActivePlayerIdNode: Record<PlayerId, true> = {};
  const playersWithLinkedProfiles: { player: Player; playerBundle: PlayerBundle }[] = [];
  const pendingTeamAccountNode: Record<AccountId, Team__Account> = {};

  // Pull over any staff to start. The account node on team is strange because it really is the master location of data for staff but the athlete and guardian roles are derived from other data
  Object.keys(team.accounts || {}).forEach(accountId => {
    if (team.accounts[accountId]?.roles?.staff) {
      pendingTeamAccountNode[accountId] = {
        exists: true,
        additionalPermissions: team.accounts[accountId]?.additionalPermissions || {},
        roles: { staff: true },
        staffTitle: team.accounts[accountId]?.staffTitle || Team__StaffTypes.staffMember
      };
    }
  });

  if (activePlayers.length > 0) {
    // **********************
    // Figure out team.account node
    // **********************
    await Promise.all(
      activePlayers.map(async player => {
        try {
          if (!player.linkedPlayerBundleId) {
            return;
          }
          const playerBundle = await h.PlayerBundle.getDoc(player.linkedPlayerBundleId);
          if (!playerBundle) {
            return;
          }
          playersWithLinkedProfiles.push({ playerBundle, player });
        } catch (e) {
          console.error(`Trouble getting linkedPlayerBundles that should exist. TeamId: ${p.teamId}. PlayerId: ${player.id} `);
        }
      })
    );
    // Figure out any permissions for guardians and athletes
    playersWithLinkedProfiles.forEach(pl => {
      if (pl.playerBundle.managingAccounts) {
        for (let accountId in pl.playerBundle.managingAccounts) {
          if (pl.playerBundle.managingAccounts[accountId]?.type === "guardian") {
            // Check if they already have an account node (aka they are staff) and create or add guardian role
            if (pendingTeamAccountNode[accountId]) {
              if (pendingTeamAccountNode[accountId].roles) {
                pendingTeamAccountNode[accountId].roles.guardian = true;
              }
            } else {
              pendingTeamAccountNode[accountId] = { exists: true, roles: { guardian: true } };
            }
          }

          if (pl.playerBundle.managingAccounts[accountId]?.type === "selfAthlete") {
            // Check if they already have an account node (aka they are staff) and create or add athlete role
            if (pendingTeamAccountNode[accountId]) {
              if (pendingTeamAccountNode[accountId].roles) pendingTeamAccountNode[accountId].roles.athlete = true;
            } else {
              pendingTeamAccountNode[accountId] = { exists: true, roles: { athlete: true } };
            }
          }

          // If they were given additional permissions before we preserve that
          if (team.accounts[accountId]?.additionalPermissions) {
            pendingTeamAccountNode[accountId].additionalPermissions = team.accounts[accountId]?.additionalPermissions;
          }
        }
      }
    });

    // **********************
    // Figure out team.derived.activePlayerIds node
    // **********************
    pendingDerivedActivePlayerIdNode = activePlayers.reduce((acc: Record<PlayerId, true>, val) => {
      acc[val.id] = true;
      return acc;
    }, {});
  }

  //Update accounts & player bundles connected with the team
  tasks.push(
    ..._.flatten(
      await Promise.all(
        Object.keys(pendingTeamAccountNode).map(accId => getBatchTasksToUpdateDerivedForAccount({ accountId: accId }))
      )
    ),
    ..._.flatten(
      await Promise.all(activePlayerBundles.map(a => getBatchTasksToUpdateDerivedForPlayerBundle({ playerBundleId: a.id })))
    )
  );

  const expectedDerivedActivePlayerBundleIds = _(activePlayerBundles)
    .keyBy(a => a.id)
    .mapValues(() => true as const)
    .value();

  if (jsonStableStringify(team.derived.activePlayerBundleIds) !== jsonStableStringify(expectedDerivedActivePlayerBundleIds)) {
    tasks.push(
      await h.Team.setPath(
        {
          id: team.id,
          pathObj: { derived: { activePlayerBundleIds: true } },
          value: { derived: { activePlayerBundleIds: expectedDerivedActivePlayerBundleIds } }
        },
        { returnBatchTask: true }
      )
    );
  }

  if (jsonStableStringify(team.accounts) !== jsonStableStringify(pendingTeamAccountNode)) {
    // Check if we need to update any nodes or if everything appears to be correct
    tasks.push(
      await h.Team.setPath(
        { id: team.id, pathObj: { accounts: true }, value: { accounts: pendingTeamAccountNode } },
        { returnBatchTask: true }
      )
    );
  }

  if (jsonStableStringify(team.derived.activePlayerIds) !== jsonStableStringify(pendingDerivedActivePlayerIdNode)) {
    tasks.push(
      await h.Team.setPath(
        {
          id: team.id,
          pathObj: { derived: { activePlayerIds: true } },
          value: { derived: { activePlayerIds: pendingDerivedActivePlayerIdNode } }
        },
        { returnBatchTask: true }
      )
    );
  }

  if (team.enabledFeatures?.squads && team.squads && team.squadsPlayerMapping) {
    const newMapping = compute.team.teamExtractSquadKeys(team.squadsPlayerMapping).reduce(
      (acc, val) => {
        if (team.squadsPlayerMapping) {
          if (team.squadsPlayerMapping[val] && activePlayers.find(pl => pl.id === val)) {
            acc[val] = team.squadsPlayerMapping[val];
          }
        }
        return acc;
      },
      {} as Record<
        string,
        {
          a?: true | undefined;
          b?: true | undefined;
          c?: true | undefined;
        }
      >
    );
    if (jsonStableStringify(team.squadsPlayerMapping) !== jsonStableStringify(newMapping)) {
      tasks.push(
        await h.Team.setPath(
          {
            id: team.id,
            pathObj: { squadsPlayerMapping: true },
            value: { squadsPlayerMapping: newMapping }
          },
          { returnBatchTask: true }
        )
      );
    }
  }

  if (p.executeImmediate) {
    await Promise.all(_.chunk(tasks, 500).map(tasksChunk => h._BatchRunner.executeBatch(tasksChunk)));

    return tasks;
  } else {
    return tasks;
  }
  // SERVER_ONLY_TOGGLE
}

// i18n certified - complete
