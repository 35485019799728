import { createBifrostSubscription, BifrostSubscription } from "@ollie-sports/react-bifrost";
import { FirestoreLiftDocsSubscription } from "@ollie-sports/firebase-lift";
import { Email } from "@ollie-sports/models";
import { SimpleQuery, OptionalQuery, QueryResultSet, FirestoreLiftCollection } from "@ollie-sports/firebase-lift";
import { common__hashObject } from "../api";
import _ from "lodash";

type ManualChange<T> = { docId: string; updateObj: Partial<T> };

type SubscriptionVal<T> = {
  docs: T[];
  fetchMore?: (() => Promise<boolean>) | undefined;
  manuallyUpdateDoc?: (a: ManualChange<T>) => void;
};

export type PaginatedSubscriptionReturnType<T> = BifrostSubscription<SubscriptionVal<T>>;

export function mapBifrostSubscription<T, R>(sub: BifrostSubscription<T>, mapFn: (a: T) => R): BifrostSubscription<R> {
  const newSub = createBifrostSubscription<R>({
    dispose: sub.dispose
  });

  sub.onData(val => newSub.nextData(mapFn(val)));

  sub.onError(e => newSub.nextError(e));

  return newSub;
}

export function convertToBifrostMultiDocSubscription<Model>(p: { subRef: FirestoreLiftDocsSubscription<Model> }) {
  const disposeFns: Function[] = [];

  const instance = createBifrostSubscription<Array<Model | null>>({
    dispose: () => {
      disposeFns.forEach(fn => fn());
    }
  });

  const sub = p.subRef.subscribe(
    val => {
      if (val) {
        instance.nextData(val);
      }
    },
    e => {
      instance.nextError(e);
    }
  );

  disposeFns.push(sub.unsubscribe);

  return instance;
}

type DocsBySource<T> = {
  subscription: T[];
  pages: { docs: T[]; nextQuery?: SimpleQuery<T> }[];
  manualChanges: ManualChange<T>[];
};

//Returns a bifrost instance that merges a firestore subscription to future data and paginated data going backwards (triggered by calling fetchMore).
const queryCache: Record<string, DocsBySource<any>> = {};
export function getSubscriptionWithPaginatedFetchMore<T extends { id: string; createdAtMS: number }>(p: {
  model: FirestoreLiftCollection<T>;
  baseFilterCriteria: OptionalQuery<T>[];
  pastFutureDividerMS?: number;
  pageSize?: number;
  onError: (err: unknown) => void;
}): PaginatedSubscriptionReturnType<T> {
  const queryId = common__hashObject({
    obj: {
      model: p.model.generateId().split("-").shift(),
      filter: p.baseFilterCriteria,
      divider: p.pastFutureDividerMS,
      pageSize: p.pageSize
    }
  });

  const disposeFns: Function[] = [];

  const bifrostSub = createBifrostSubscription<{
    docs: T[];
    fetchMore?: () => Promise<boolean>;
    manuallyUpdateDoc?: (a: ManualChange<T>) => void;
  }>({
    dispose: () => {
      try {
        disposeFns.forEach(fn => fn());
      } catch (e) {
        console.error(e);
      }
    }
  });

  const docsBySource: DocsBySource<T> = queryCache[queryId] || {
    subscription: [],
    pages: [],
    manualChanges: []
  };

  queryCache[queryId] = docsBySource;

  //Attempt to fix weird bug where items are missing. Maybe if the past/future divider stays it will help stable?
  const now = p.pastFutureDividerMS ?? Date.now();

  const firstPageProm = docsBySource.pages.length
    ? Promise.resolve()
    : p.model
        .query({
          where: p.baseFilterCriteria.concat({ createdAtMS: ["<=", now] }),
          orderBy: [{ pathObj: { createdAtMS: true }, dir: "desc" }],
          limit: p.pageSize || 20
        })
        .then(page => {
          handlePage(page);
        });

  let hasSubscriptionEverFired = false;
  const handlePage = (page: QueryResultSet<T>) => {
    docsBySource.pages = docsBySource.pages.concat(_.omit(page, "rawDocs"));
    sendNextData();
  };

  let fetchingMorePromise: undefined | Promise<boolean>;
  async function fetchMoreFn(): Promise<boolean> {
    await firstPageProm;

    const nextQuery = docsBySource.pages.slice(-1).pop()?.nextQuery;

    if (fetchingMorePromise) {
      return await fetchingMorePromise;
    }

    if (!docsBySource.pages.slice(-1).pop()) {
      return false;
    }

    fetchingMorePromise = new Promise<boolean>(async (resolve, reject) => {
      try {
        let didFetchMore: boolean;
        if (nextQuery) {
          const nextPage = await p.model.query(nextQuery);
          handlePage(nextPage);
          didFetchMore = true;
        } else {
          didFetchMore = false;
        }

        resolve(didFetchMore);
      } catch (e) {
        p.onError(e);
        reject(e);
      }
    });

    fetchingMorePromise.finally(() => {
      fetchingMorePromise = undefined;
    });

    return fetchingMorePromise;
  }

  function sendNextData() {
    if (!docsBySource.pages.length || !hasSubscriptionEverFired) {
      return;
    }

    const nextQuery = docsBySource.pages.slice(-1).pop()?.nextQuery;

    const fetchMore = nextQuery ? fetchMoreFn : undefined;
    const manuallyUpdateDoc = (a: { docId: string; updateObj: Partial<T> }) => {
      docsBySource.manualChanges.push(a);
      sendNextData();
    };

    let newDocs = docsBySource.subscription.concat(_.flatten(docsBySource.pages.map(a => a.docs)));

    if (docsBySource.manualChanges.length) {
      //These type of manual changes shouldn't be very common at all. So I think it's okay to simply transform the array before sending out the next value
      newDocs = newDocs.map(a => {
        const changesToApply = docsBySource.manualChanges.filter(b => b.docId === a.id);

        if (changesToApply.length) {
          const newDoc = { ...a };
          changesToApply.forEach(({ updateObj }) => Object.assign(newDoc, updateObj));
          return newDoc;
        } else {
          return a;
        }
      });
    }

    const nextVal = {
      docs: newDocs,
      fetchMore,
      manuallyUpdateDoc
    };

    bifrostSub.nextData(nextVal, { skipEqualityCheck: true });
  }

  const sub = p.model
    .querySubscription({
      where: p.baseFilterCriteria.concat({ createdAtMS: [">", now] }),
      orderBy: [{ pathObj: { createdAtMS: true }, dir: "desc" }]
    })
    .subscribe(
      async data => {
        docsBySource.subscription = data.docs;
        hasSubscriptionEverFired = true;
        sendNextData();
      },
      err => {
        console.error(err);
      }
    );

  disposeFns.push(sub.unsubscribe);

  return bifrostSub;
}

// i18n certified - complete
